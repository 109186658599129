import React, {useEffect, useRef} from "react"
import {tryToLoad} from "../helpers"


const navOptions = {
  'draggable' : false 
};

const SideNav = (props, ref) => {

  const sideNavRef = useRef(null);

  useEffect(() => { 
    tryToLoad( () => window.M.Sidenav.init(sideNavRef.current , navOptions));    
  },[]);

  return(
    <div ref = {sideNavRef} {...props}>
      <a className="sidenav-close"></a>
     <ul>
      <li><a href="https://www.astrasuites.com/accommodation/#standardsuites">Standard Suites</a></li>
      <li><a href="https://www.astrasuites.com/accommodation/#luxurysuites">Luxury Suites</a></li>
      <li><a href="https://www.astrasuites.com/accommodation/#villas">Villas</a></li>
      <li><a href="https://www.astrasuites.com/contact/">Contact</a></li>
    </ul>
    <ul>
      <li><strong>Astra Suites Santorini</strong></li>
      <li>Imerovigli Santorini, </li>
      <li>PC 84 700 Greece</li>
      <li><a href="tel:+302286023641">T. +30 228 602 3641</a></li>
      <li>F. +30 228 602 4765</li>
      <li><a href="mailto:info@astrasuites.com">E. info@astrasuites.com</a></li>
    </ul>
    </div>
  );
};


export default SideNav;