import React from "react"
import {Link} from "react-router-dom"


const HeaderLogo = ({image}) => {

  const style = {
    background: `url(${image}) center center no-repeat`
  };

  return(
     <header className="row">
      <Link to="/" id="logo" style={style}>Online Checkin</Link>  <a href="tel:+302286023641" className="tel"></a>
     </header>
  );
};

export default HeaderLogo;